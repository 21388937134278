import React, { useState } from "react";
import { Form, Button, FloatingLabel, Container, Modal } from "react-bootstrap";
import './Login.css';

const Login = (props) => {
    const [loginForm, setLoginForm] = useState({});
    const [showError, setShowError] = useState(false);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        setLoginForm({
            ...loginForm,
            [name]: value
        });
    };

    function validateForm() {
        let result = false;
        if (loginForm.userName
            && loginForm.userName.length > 0
            && loginForm.userName === 'admin'
            && loginForm.userPassword
            && loginForm.userPassword.length > 2
            && loginForm.userPassword.substr(loginForm.userPassword.length - 3, loginForm.userPassword.length) === generatePass())
            result = true;
        return result;
    }

    function handleKeyUp(event) {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        // Show/Hide Login
        if (validateForm()) {
            props.setToken(true);
        }
        setShowError(true);
    }

    function generatePass() {
        const today = new Date();
        const pass = '' + Math.trunc((`${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`) / 3);
        return pass.substr(0, 3);
    }

    return (
        <Container>
            <main className="form-signin">
                <div className="text-center">
                    <Form>
                        <h1 className="h3 mb-4 fw-normal">Please Login</h1>
                        <FloatingLabel controlId="userName" label="User Name">
                            <Form.Control onKeyUp={handleKeyUp} onChange={handleChange} type="text" placeholder="User" />
                        </FloatingLabel>
                        <FloatingLabel controlId="userPassword" label="Password">
                            <Form.Control onKeyUp={handleKeyUp} onChange={handleChange} type="password" placeholder="Password" />
                        </FloatingLabel>
                        <div className="d-grid gap-2">
                            <Button onClick={handleSubmit} variant="primary" size="lg">Sign in</Button>
                        </div>
                    </Form>
                </div>
            </main>

            <Modal show={showError} onHide={() => setShowError(false)} centered>
                <Modal.Header className='bg-danger text-white' closeVariant='white' closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Username or Password is wrong or changed !
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default Login;
import { useState } from 'react';
import { Button, Badge, Form, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { sha256 } from 'js-sha256';
import axios from 'axios';

const defaultDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 3);
    return today.toISOString().substr(0, 10);
};

const defaultBranch = [
    {
        "_id": "617ebd57e62be2000d9df165",
        "branch_name": "(044 ) Hlaing Branch ",
        "branch_address": "No.41,Bayintnaung Street,Aye Yaik Mon Housing,Hlaing Township,Yangon.",
        "branch_key": "(044_)_hlaing_branch_"
    },
    {
        "_id": "6081646948ace20008f3a223",
        "branch_name": "Bayintnaung -3 Branch",
        "branch_address": "No(Sa/46), Sein Pan St, (1) Qtr, Bayint Naung Commondity Exchange Centre, Mayangone Tsp, Ygn.",
        "branch_key": "bayintnaung_-3_branch"
    },
    {
        "_id": "60858a1c72938600082ea0e4",
        "branch_name": "DawPone",
        "branch_address": "No.(265/267/269),Minnandar Road,Burmaaye QTR,,DawBon Township,Yangon",
        "branch_key": "dawpone"
    },
    {
        "_id": "607dbf2cf278840008e3e271",
        "branch_name": "INTERNATIONAL BANKING DIVISION",
        "branch_address": "No(53), Corner of Merchant St & Bo Son Pat St, Pabedan Tsp, Yangon Division.",
        "branch_key": "international_banking_division"
    },
    {
        "_id": "607ee679ff87bb0006da607c",
        "branch_name": "LANMADAW BRANCH",
        "branch_address": "No (01-06/07), Lanmadaw Condo Centre, Lanmadaw Plaza, Lanmadaw Rd, Latha Tsp, Yangon",
        "branch_key": "lanmadaw_branch"
    },
    {
        "_id": "608f951cc6626d0009fe94b2",
        "branch_name": "Merchant street",
        "branch_address": "N0-537,539 Corner of Merchant Street and Pansodan Street, Kyauktada Tsp.",
        "branch_key": "merchant_street"
    },
    {
        "_id": "607ee2f6211082000895a15a",
        "branch_name": "Mingalarzay Branch 1",
        "branch_address": "Building-1, Room No(01-03-05), Set Yone St, Mingalar Taung Nyunt Tsp,Yangonn",
        "branch_key": "mingalarzay_branch_1"
    },
    {
        "_id": "607dbfb4f278840008e3e27b",
        "branch_name": "NINE MILES BRANCH",
        "branch_address": "No-142, Airport View Tower (B), Pyi Road , (5) Qtr, 9-mile, Mayangone Tsp, Yangon.",
        "branch_key": "nine_miles_branch"
    },
    {
        "_id": "6106c0fee0eea4000d38c2d3",
        "branch_name": "North Dagon-3",
        "branch_address": "NO-1055,PYI HTAUNG SU STR,38 QT,NORTH DAGON,YANGON",
        "branch_key": "north_dagon-3"
    },
    {
        "_id": "617eb2bfa8055d000da095ff",
        "branch_name": "North Okkalapa-2 (YGN-94)",
        "branch_address": "No.(76/B,77/A),Thudamar Street , 2-Qtr,North OkkalapaTownship,Yangon.",
        "branch_key": "north_okkalapa-2_(ygn-94)"
    },
    {
        "_id": "60858af62351a90009884349",
        "branch_name": "Pazundaung",
        "branch_address": "No(58), WinShweYee Plaza, Upper PZD Rd,  Pazundaung Township , Yangon.",
        "branch_key": "pazundaung"
    }
];

const defaultValue = {
    token: "",
    accountNumberChequeIndividual: "",
    appointmentdate: defaultDate(),
    slot: "12:00-13:00",
    cityId: "606c79b9c4476500089e5eca",
    townId: "",
    id: "",
    serviceId: "607edd57ff87bb0006da6042",
    serviceKey: "cash_withdrawal_branch",
    accountNumberMmkIndividual: "",
    amountCheque: "500000",
    amountMmk: "",
    accountNumberMmkCorporate: "",
    accountNumberChequeCorporate: "",
    amountCorporateCheque: "",
    amountCorporateMmk: "",
    customerType: "",
    typeOfUser: "CUSTOMER",
    loginURL: ""
};

const Book = () => {
    const [branches, setBranches] = useState(defaultBranch);
    const [inputForm, setInputForm] = useState(defaultValue);
    const [count, setCount] = useState(0);
    const [intervalId, setIntervalId] = useState(0);
    const [res, setRes] = useState({});
    const [info, setInfo] = useState(null);
    const [userInfo, setUserInfo] = useState({});
    const [disableUpdate, setDisableUpdate] = useState(false);
    const [disableInfo, setDisableInfo] = useState(false);
    // const [disableDelete, setDisableDelete] = useState(false);
    const [retrivingToken, setRetrivingToken] = useState(false);
    const [modal, setModal] = useState({ show: false });

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        setInputForm({
            ...inputForm,
            [name]: value
        });
    };

    const handleRemark = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        document.title = value;
    };

    const handleToken = () => {
        setRetrivingToken(true);
        const queryString = inputForm.loginURL ? inputForm.loginURL.split('?')[1] : "";
        const params = new URLSearchParams(queryString);

        const tokenData = {
            urlparams: params.get('kbzpay_token'),
            appid: "kpc3553c61a9d1472b88fbd45b64504b",
            merch_code: "700002",
            merch_key: "05650c825e3cbaf591f6756bb3e0f059",
            nonce: generateRandomString(32),
            currenttime: Math.floor(new Date().getTime() / 1000),
        };

        const signstring = "access_token=" + tokenData.urlparams + "&appid=" + tokenData.appid + "&merch_code=" + tokenData.merch_code + "&method=kbz.payment.queryCustInfo&nonce_str=" + tokenData.nonce + "&resource_type=OPENID&timestamp=" + tokenData.currenttime + "&trade_type=APPH5&version=1.0&key=" + tokenData.merch_key;
        const shastring = sha256(signstring);

        const biz_content = {
            merch_code: tokenData.merch_code,
            appid: tokenData.appid,
            trade_type: "APPH5",
            access_token: tokenData.urlparams,
            resource_type: "OPENID"
        };

        const requestdata = {
            Request: {
                method: "kbz.payment.queryCustInfo",
                timestamp: String(tokenData.currenttime),
                nonce_str: tokenData.nonce,
                sign_type: "SHA256",
                sign: shastring,
                version: "1.0",
                biz_content: biz_content
            }
        };

        // console.log(tokenData);
        // console.log(signstring);
        // console.log(shastring);
        // console.log(requestdata);

        axios({
            method: 'post',
            url: 'https://appointments-api.kbzbank.com/quote',
            data: requestdata
        }).then(response => {
            // console.log(response.status);
            // console.log(response.data);
            setInputForm({
                ...inputForm,
                token: response.data.token,
            });
            // setRetrivingToken(false);

            // Get User Info
            axios({
                method: 'post',
                url: 'https://api.kbzpay.com/web/gateway/uat/queryCustInfo',
                data: requestdata
            }).then(response => {
                // console.log(response.status);
                // console.log(response.data);
                setUserInfo(response.data.Response.customer_info);
                setRetrivingToken(false);
            });
        }).catch(error => {
            console.log(error);
            handleError(error);
            setRetrivingToken(false);
        });
    };

    const handleReset = () => {
        setBranches(defaultBranch);
        setInputForm(defaultValue);
        document.getElementById('remark').value = '';
        document.title = "My Booking";
        setInfo(null);
        setUserInfo({});
    };

    const handleClick = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(0);
            return;
        }

        setCount(0);
        setRes({});
        const newIntervalId = setInterval(() => {
            setCount(prevCount => prevCount + 1);
            // console.log(inputForm);
            handleRequest(newIntervalId);
        }, 1000);
        setIntervalId(newIntervalId);
    };

    const handleRequest = (newIntervalId) => {
        axios({
            method: 'post',
            url: 'https://appointments-api.kbzbank.com/bookAppointmentModifiedDirect',
            data: inputForm
        }).then(response => {
            // console.log(response.status);
            // console.log(response.data);
            setRes({
                status: response.status,
                message: response.data.message
            });
            if (newIntervalId) {
                clearInterval(newIntervalId);
                setIntervalId(0);
            }
            setModal({
                show: true,
                title: 'Info',
                msg1: 'Congratulation !',
                msg2: 'You got an appointment.'
            });
        }).catch(error => {
            if (error.response && error.response.status !== 400 && newIntervalId) {
                clearInterval(newIntervalId);
                setIntervalId(0);
            }
            handleError(error);
        });
    };

    const handleUpdate = () => {
        setDisableUpdate(true);
        const data = {
            "serviceid": "607edd57ff87bb0006da6042",
            "cityId": inputForm.cityId,
            "token": inputForm.token,
            "typeOfUser": "CUSTOMER"
        };
        axios({
            method: 'post',
            url: 'https://appointments-api.kbzbank.com/getUpdatedBranchByService',
            data: data
        }).then(response => {
            // console.log(response.status);
            // console.log(response.data);
            setDisableUpdate(false);
            setBranches(response.data.data);
            setRes({
                status: response.status,
                message: response.data.message
            });
            // setModal({
            //     show: true,
            //     title: 'Info',
            //     msg1: 'Branch List is updated !',
            //     msg2: 'Choose a branch for booking.'
            // });
        }).catch(error => {
            setDisableUpdate(false);
            handleError(error);
        });
    };

    const handleInfo = () => {
        setDisableInfo(true);
        const data = {
            "token": inputForm.token,
            "typeOfUser": "CUSTOMER"
        };
        axios({
            method: 'post',
            url: 'https://appointments-api.kbzbank.com/viewAppointment',
            data: data
        }).then(response => {
            // console.log(response.status);
            // console.log(response.data);

            setDisableInfo(false);
            const appointment = {
                appointment_reference_number: response.data.data.appointment_reference_number,
                appointment_booked_by: response.data.data.appointment_booked_by,
                registered_mobile_number: response.data.data.registered_mobile_number,
                withdrawal_account_number_individual_cheque: response.data.data.withdrawal_account_number_individual_cheque,
                withdrawal_amount_cheque: response.data.data.withdrawal_amount_cheque,
                date_of_appointment: response.data.data.date_of_appointment,
                time_slot: response.data.data.time_slot,
                branch_id: response.data.data.branch_id,
                branchName: response.data.nameArray.branchName,
                app_level: response.data.data.app_level,
                status: response.data.data.status,
                service_id: response.data.data.service_id[0],
                serviceName: response.data.nameArray.serviceName,
            };
            const strJSON = JSON.stringify(appointment, undefined, 4);
            setInfo(strJSON);
            setRes({
                status: response.status,
                message: response.data.message
            });
        }).catch(error => {
            setDisableInfo(false);
            handleError(error);
        });
    };

    // const handleDelete = () => {
    //     setDisableDelete(true);
    //     const infoJSON = JSON.parse(info);
    //     const data = {
    //         "id": infoJSON && infoJSON.branch_id,
    //         "appointmentdate": infoJSON && infoJSON.date_of_appointment,
    //         "slot": infoJSON && infoJSON.time_slot,
    //         "token": inputForm.token,
    //         "refnumber": infoJSON && infoJSON.appointment_reference_number,
    //         "serviceId": infoJSON && infoJSON.service_id,
    //         "typeOfUser": "CUSTOMER"
    //     };
    //     axios({
    //         method: 'post',
    //         url: 'https://appointments-api.kbzbank.com/cancelAppointmentModifiedDirect',
    //         data: data
    //     }).then(response => {
    //         // console.log(response.status);
    //         // console.log(response.data);

    //         setDisableDelete(false);

    //         setModal({
    //             show: true,
    //             title: 'Info',
    //             msg1: response.data.message,
    //             msg2: 'Thanks'
    //         });
    //         setRes({
    //             status: response.status,
    //             message: response.data.message
    //         });
    //     }).catch(error => {
    //         setDisableDelete(false);
    //         handleError(error);
    //     });
    // };

    const handleError = (error) => {
        if (error.response) {
            setRes({
                status: error.response.status,
                message: error.response.data.message
            });
            if (error.response.status === 401 && error.response.data.message === 'UNAUTHORIZED') {
                setModal({
                    show: true,
                    title: 'Error',
                    msg1: 'Login Toke has error !',
                    msg2: 'Invalid Token or Session has been expired.'
                });
            } else if (error.response.status === 405) {
                setModal({
                    show: true,
                    title: 'Error',
                    msg1: 'Booking has error !',
                    msg2: 'Invalid id or information has been submitted.'
                });
            } else if (error.response.status === 400) {
            } else if (error.response.status === 305) {
                setModal({
                    show: true,
                    title: 'Error',
                    msg1: 'Service Limit is Over !',
                    msg2: 'Please wait a moment and try again.'
                });
            } else {
                setModal({
                    show: true,
                    title: 'Error',
                    msg1: 'Unknown Error Code !',
                    msg2: 'Please check the input and try again.'
                });
            }
        } else if (error.request) {
            console.log(error.request);
            setRes({
                status: '000',
                message: 'Connection Error'
            });
            // setModal({
            //     show: true,
            //     title: 'Error',
            //     msg1: 'Network connection has been lost !',
            //     msg2: 'Check your internet connection.'
            // });
        } else {
            console.log('Error', error.message);
            setModal({
                show: true,
                title: 'Error',
                msg1: 'Unknown Error Type !',
                msg2: 'Please check the input and try again.'
            });
        }
    };

    const generateRandomString = (length) => {
        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        var charactersLength = characters.length;

        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    return (
        <div>
            <h2>KBZ Appointment (Book)</h2>
            <Form>
                <fieldset disabled={intervalId ? true : false}>
                    <Form.Group className="mb-3" controlId="loginURL">
                        <Form.Label>KPay Login URL</Form.Label>
                        <Row>
                            <Col xs={12} sm={8} md={10} className="mb-2">
                                <Form.Control value={inputForm.loginURL} onChange={handleChange} type="text" placeholder="https://appointments.kbzbank.com/booking?kbzpay_token=3c45d08eeda5b424898666788841744d" />
                            </Col>
                            <Col xs={12} sm={4} md={2}>
                                <div className="d-grid gap-2">
                                    <Button onClick={handleToken} variant="warning">
                                        {retrivingToken ?
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> :
                                            'Get'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Badge bg="success">{userInfo.msisdn}</Badge>{' '}
                    <Badge bg="success" className="mb-3">{userInfo.fullName}</Badge>
                    <Form.Group className="mb-3 d-none" controlId="token">
                        <Form.Label>KPay Login Token</Form.Label>
                        <Form.Control value={inputForm.token} onChange={handleChange} as="textarea" rows={3} minLength="296" maxLength="296" />
                        <Form.Text className="text-muted" >Retrive token from cookies. ({296 - inputForm.token.length})</Form.Text>
                    </Form.Group>
                    <Row>
                        <Col xs={12} sm={6} md={6}>
                            <Form.Group className="mb-3" controlId="accountNumberChequeIndividual">
                                <Form.Label>Bank Account No</Form.Label>
                                <Form.Control value={inputForm.accountNumberChequeIndividual} onChange={handleChange} type="text" minLength="17" maxLength="17" />
                                <Form.Text className="text-muted" >Check from saving book. ({17 - inputForm.accountNumberChequeIndividual.length})</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} md={6}>
                            <Form.Group className="mb-3" controlId="cityId">
                                <Form.Label>City</Form.Label>
                                <Form.Select value={inputForm.cityId} onChange={handleChange} aria-label="City">
                                    <option value="">Choose a City</option>
                                    <option value="606c79b9c4476500089e5eca">Yangon</option>
                                    <option value="60941f20838a3200093d0cec" disabled="disabled">Mandalay</option>
                                    <option value="609421dbd17bad0009ba2ef3" disabled="disabled">Naypyitaw</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6}>
                            <Form.Group className="mb-3" controlId="id">
                                <Form.Label>Branch</Form.Label>
                                <Row>
                                    <Col xs={12} sm={8} className="mb-2">
                                        <Form.Select value={inputForm.id} onChange={handleChange} aria-label="Branch" disabled={branches.length === 0 ? true : false}>
                                            <option value="">Choose a branch</option>
                                            {
                                                branches.map(branch => <option key={branch._id} value={branch._id}>{branch.branch_name}</option>)
                                            }
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <div className="d-grid gap-2">
                                            <Button onClick={handleUpdate} variant="warning">
                                                {disableUpdate ?
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> :
                                                    'Update'}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} md={3}>
                            <Form.Group className="mb-3" controlId="appointmentdate">
                                <Form.Label>Date</Form.Label>
                                <Form.Control value={inputForm.appointmentdate} onChange={handleChange} type="date" />
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} md={3}>
                            <Form.Group className="mb-3" controlId="slot">
                                <Form.Label>Time</Form.Label>
                                <Form.Select value={inputForm.slot} onChange={handleChange} aria-label="Time">
                                    <option value="">Choose a slot</option>
                                    <option value="10:00-11:00">10:00-11:00</option>
                                    <option value="11:00-12:00">11:00-12:00</option>
                                    <option value="12:00-13:00">12:00-13:00</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="remark">
                        <Form.Label>Remark</Form.Label>
                        <Form.Control type="text" onChange={handleRemark} />
                        <Form.Text className="text-muted" >This is for personal note. (Optional)</Form.Text>
                    </Form.Group>
                </fieldset>
                <Row>
                    <Col xs={12} sm={8} md={10}>
                        <div className="d-grid gap-2 mb-2">
                            <Button onClick={handleClick} size="lg" variant={intervalId ? "danger" : "primary"}>{intervalId ? 'STOP' : 'RUN'}</Button>
                        </div>
                    </Col>
                    <Col xs={12} sm={4} md={2}>
                        <div className="d-grid gap-2">
                            <Button onClick={handleReset} size="lg" variant="secondary" disabled={intervalId ? true : false}>Reset</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
            <hr />
            <p>
                {!intervalId ?
                    <Badge bg="secondary">Stopped</Badge> :
                    <span>
                        <Badge bg="warning" text="dark">Running...</Badge> <Spinner animation="grow" size="sm" variant="danger" />
                    </span>
                }{' '}
                {count > 0 || res.status > 0 ?
                    <span>
                        <Badge bg="warning" text="dark">{count}</Badge>{' '}
                        <Badge bg={res.status === 200 ? "success" : "danger"}>{res.status}</Badge>{' '}
                        <Badge bg="info" text="dark">{res.message}</Badge>
                    </span> :
                    ''
                }
            </p>

            <Row>
                {/* <Col xs={12} sm={8} md={10}> */}
                <Col xs={12} sm={12} md={12}>
                    <div className="d-grid gap-2 mb-2">
                        <Button onClick={handleInfo} variant="warning" disabled={intervalId ? true : false}>
                            {disableInfo ?
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> :
                                'INFO'}
                        </Button>
                    </div>
                </Col>
                {/* <Col xs={12} sm={4} md={2}>
                    <div className="d-grid gap-2 mb-3">
                        <Button onClick={handleDelete} variant="danger" disabled={intervalId ? true : false}>
                            {disableDelete ?
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> :
                                'Delete'}
                        </Button>
                    </div>
                </Col> */}
            </Row>
            {info !== null ?
                <Form.Control value={info} className="mb-3" disabled="disabled" onChange={handleChange} as="textarea" rows={12} /> : ""
            }

            <Modal show={modal.show} onHide={() => setModal({ ...modal, show: false })} centered>
                <Modal.Header className={[modal.title === 'Info' ? 'bg-success' : 'bg-danger', 'text-white']} closeVariant='white' closeButton>
                    <Modal.Title>{modal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modal.msg1}<br />{modal.msg2}
                </Modal.Body>
                <Modal.Footer>
                    <Badge bg={res.status === 200 ? "success" : "danger"}>{res.status}</Badge>{' '}
                    <Badge bg="info" text="dark">{res.message}</Badge>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Book;
import { Container, Navbar, Nav, Tab, Tabs } from 'react-bootstrap';
import Book from './components/Book';
// import Agent from './components/Agent';
import Login from "./components/Login";
import { useState } from 'react';

const App = () => {
    const [token, setToken] = useState();
    const [key, setKey] = useState('book');
    if (!token) {
        return <Login setToken={setToken} />;
    }

    return (
        <div className="App">
            <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark" fixed="top">
                <Container>
                    <Navbar.Brand href="#home">My Booking</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#about">About</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className="mt-5 pt-4">
                <Tabs defaultActiveKey="profile" id="controlled-booking-tab" className="mb-3" activeKey={key}
                    onSelect={(k) => setKey(k)}>
                    <Tab eventKey="book" title="Book">
                        <Book />
                    </Tab>
                    {/* <Tab eventKey="agent" title="Agent">
                        <Agent />
                    </Tab> */}
                </Tabs>
            </Container>
        </div>
    );
};

export default App;